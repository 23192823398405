import React from 'react'

import { ApolloProvider } from 'react-apollo'
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import numbro from 'numbro'
import { withSearchkit } from 'searchkit/client';

import localeMessages from '../locales/locale_messages.yml'
import client from '../apollo-client-v2'
import Routes from '../routes'
import theme from '../themes/plataformaAlerta'
import ToastContainer from '../../components/ToastContainer'

numbro.registerLanguage({
  languageTag: 'pt-BR',
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => 'º',
  currency: {
    symbol: 'R$ ',
    position: 'prefix'
  }
})

numbro.setLanguage('pt-BR')

class App extends React.Component {
  render() {
    
    const locale = localStorage.getItem('locale') || 'pt-BR'

    return (
      <ApolloProvider client={ client }>
        <ThemeProvider theme={ theme }>
          <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={ MomentUtils }>
              <IntlProvider key={ locale } locale={ locale } messages={ flatten(localeMessages[locale]) }>
                <Routes />
                <ToastContainer />
              </IntlProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

export default withSearchkit(App);
